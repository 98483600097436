import { useTranslation } from 'react-i18next';
import AppAnalyticsCountIcon from './AppAnalyticsCountIcon';
import { ReactComponent as CallBigIcon } from '../../../../assets/icons/home page icons/CallBig.svg';
import { useTotalCount } from '../../../../shared/appBackend/useMetrics';
import { ReactNode } from 'react';
import AppShowLoading from '../../../../shared/appUIFramework/components/AppShowLoading';
import { useFormatNumber } from '../../../../shared/formatters/formatNumber';

function CallRateSection(
  { icon, count, title, prevCount, prevTitle, isGreen }: {
    icon: ReactNode;
    count?: number;
    title: string;
    prevCount?: number;
    prevTitle: string;
    isGreen?: boolean;
  },
) {
  const formatNumber = useFormatNumber();
  return (
    <div className='app-analytics-widget-big-icon-container app-no-border-right'>
      <div className='app-d-flex app-flex-column app-gap-10 app-align-items-center'>
        <div
          className={`app-analytics-widget-big-icon app-analytics-widget-calls-big-icon ${isGreen ? 'app-analytics-widget-calls-big-icon-green' : ''
            }`}
        >
          <AppAnalyticsCountIcon icon={icon} count={count} title={title} />
        </div>
        <div className='app-d-flex app-flex-column app-gap-5 app-align-items-center'>
          <AppShowLoading inline showLoading={prevCount == null}>
            <div className='app-font-21 app-weight-600 app-color-primary-black'>
              {formatNumber(prevCount)}
            </div>
            <div className='app-font-12 app-uppercase app-weight-600 app-color-primary-black'>
              {prevTitle}
            </div>
          </AppShowLoading>
        </div>
      </div>
    </div>
  );
}

export default function CallRateWidget({ width50 }: { width50?: boolean }) {
  const { t } = useTranslation();
  const totalCount = useTotalCount();
  const prevMonthTotalCount = useTotalCount({ prevMonth: true });

  return (
    <div className={`app-home-widget app-home-widget-call-rate ${width50 ? 'app-w-50pcnt' : ''}`}>
      <div className='app-home-widget-title app-mb-46'>
        <span className='app-home-widget-title-text'>{t('CallRate')}</span>
      </div>
      <div className='app-d-flex app-gap-20 app-mb-25'>
        <CallRateSection
          icon={<CallBigIcon />}
          count={totalCount?.callCount}
          title={t('CallsThisMonth')}
          prevTitle={t('CallsLastMonth')}
          prevCount={prevMonthTotalCount?.callCount}
        />
        <CallRateSection
          icon={<CallBigIcon />}
          count={totalCount?.callAnsweredCount}
          title={t('CallsAnsweredThisMonth')}
          prevTitle={t('CallsAnsweredLastMonth')}
          prevCount={prevMonthTotalCount?.callAnsweredCount}
          isGreen
        />
      </div>
    </div>
  );
}
