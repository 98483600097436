import { useEffect, useState } from 'react';
import { getUrlWithQueryParams, httpPost } from '../backend/http/http';
import { Urls } from '../backend/urls';
import { wsGet } from '../backend/websockets/websockets';
import { ISite } from './useSites';

export enum DeviceTypeIds {
  Unknown = 0,
  Mark1Panel,
  Mark1Monitor,
  Mark2Panel,
  TouchPanel,
  PremiumMonitor,
  Mark2Monitor,
  AudioOnly,
  Mobile = 1001,
  SIP = 1002
}

export const isMonitor = (typeId: DeviceTypeIds) => {
  return typeId === DeviceTypeIds.Mark1Monitor || typeId === DeviceTypeIds.PremiumMonitor || typeId === DeviceTypeIds.Mark2Monitor;
}

export interface IDevice {
  name: string;
  type: string;
  typeId: DeviceTypeIds;
  serialNumber: string;
  status: 'Online' | 'Offline';
}

interface IHardwareDevice {
  deviceName: string;
  deviceType: string;
  deviceTypeId: DeviceTypeIds;
  group: string;
  deviceId: string;
  serialNumber: string;
  isOnline: boolean;
}

interface IHardwareRequest {
  hardwareSummary: IHardwareDevice[];
}

export function useDevices(site: ISite | null): {
  devices: IDevice[] | undefined;
  loading: boolean;
  isOffline: boolean;
} {
  const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (!site) {
      setLoading(false);
      setDevices(undefined);
    } else {
      const wsUrl = getUrlWithQueryParams(Urls.HardwareSummary(site.id), { companyId: site.companyId });
      const wsNegotiateUrl = Urls.HardwareNegotiate(site.id);
      setLoading(true);
      wsGet<IHardwareRequest>(wsUrl, wsNegotiateUrl)
        .then(data => {
          setDevices(
            data.hardwareSummary
              .filter(device => {
                const isPanel = device.deviceType === 'Standard Panel' || device.deviceType === 'Touch Panel';
                return isPanel;
              })
              .map(device => {
                return {
                  name: device.deviceName,
                  type: device.deviceType,
                  typeId: device.deviceTypeId,
                  serialNumber: device.serialNumber,
                  status: device.isOnline ? 'Online' : 'Offline',
                };
              }),
          );
          setIsOffline(false);
        })
        .catch((error: unknown) => {
          console.error('Error fetching hardware summary', error);
          setDevices([]);
          setIsOffline(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [site]);

  return { devices, loading, isOffline };
}

export enum MessageType {
  NotSpecified = 0,

  /// <summary>
  /// bind Panel
  /// </summary>
  Bind = 1,

  /// <summary>
  /// Unbind Panel
  /// </summary>
  Unbind = 2,

  /// <summary>
  /// Reboot Panel
  /// </summary>
  Reboot = 3
}

export interface IRebootResponse {
  messageType: MessageType;
  result: string;
  code: number;
  siteId: string;
  panelSerial: string;
}

export async function rebootPanel(panelSerial: string, siteId: string, engineerCode: string): Promise<void> {
  const response = await wsGet<IRebootResponse>(async () => {
    await httpPost(Urls.DevicesReboot(panelSerial, siteId), JSON.stringify({ engineerCode }));
  }, Urls.DevicesNegotiate);

  if(response.code !== 200) {
    throw new Error('Reboot failed, the response code was not 200, but ' + response.code);
  }
}
