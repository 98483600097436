import PaxtonLogo from '../../../assets/paxton-logo.svg';
import { ReactComponent as MenuNavigationIcon } from '../../../assets/icons/mobile/Menu Navigation icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close-icon.svg';
import HomeIcon from '../../../assets/icons/Lefthand navigation icons/Home Icon.svg';
import SiteIcon from '../../../assets/icons/Lefthand navigation icons/SitesIcon.svg';
import InvoiceIcon from '../../../assets/icons/Lefthand navigation icons/Invoice icon.svg';
import EmailIcon from '../../../assets/icons/Lefthand navigation icons/Email icon grey.svg';
import SupportServerAccessIcon from '../../../assets/icons/Lefthand navigation icons/Support server access.svg';
import LogoutIcon from '../../../assets/icons/Lefthand navigation icons/Logout icon.svg';
import { Link, useRoute } from 'wouter';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppMsal } from '../../backend/auth/auth.msal';
import { AppPermissionComponentGuard } from '../permissions/AppPermissionGuard';
import { UserRole } from '../../backend/auth/auth.claims';
import { useSupportServerAccessFeatureFlags } from '../../../routes/supportServerAccess/SupportServerAccess.featureFlag';
import { useLogoutOnIDLE } from '../hooks/useLogoutOnIDLE';

function AppNavigationLink({
  to,
  childRoutes,
  children,
}: { to: string, childRoutes?: string[], children: ReactNode }) {
  const [match] = useRoute(to);
  const onImplicitChildPage = window.location.href.includes(to) && to !== '/';
  const onExplicitChildPage = childRoutes && childRoutes.includes(window.location.pathname);
  const isActive = match || onImplicitChildPage || onExplicitChildPage;
  return (
    <div>
      {isActive
        ? (
          <Link
            to={to}
            className='app-mp-bold-16 app-px-30 app-py-12 app-navigation-link app-navigation-link-active'
          >
            {children}
          </Link>
        )
        : (
          <Link
            to={to}
            className='app-mp-bold-16 app-px-30 app-py-12 app-navigation-link'
          >
            {children}
          </Link>
        )}
    </div>
  );
}

export default function PortalShell({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const msal = useAppMsal();
  const { featureFlags } = useSupportServerAccessFeatureFlags();
  useLogoutOnIDLE();
  return (
    <>
      <div className='app'>
        <div className='app-navigation-mobile'>
          { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='some-id'>
            <MenuNavigationIcon />
          </label>
          <img
            src={PaxtonLogo}
            alt='Paxton Logo'
            style={{ maxWidth: '100%' }}
          />
        </div>
        <input id='some-id' name='some-id' className='app-navigation-visible-state' type='checkbox' hidden />
        <div className='app-navigation-desktop-container'>
          { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='some-id' className='app-navigation-close'>
            <CloseIcon />
          </label>
          <div className='app-navigation-overlay' />
          <div className='app-grow-1'>
            <div className='app-navigation-logo'>
              <img
                src={PaxtonLogo}
                alt='Paxton Logo'
                style={{ maxWidth: '100%' }}
              />
            </div>
            <AppNavigationLink
              to='/home'
              childRoutes={['/home/entry-analytics', '/home/service-health']}
            >
              <img
                src={HomeIcon}
                alt='AppAnalytics'
              />
              <span>{t('Home')}</span>
            </AppNavigationLink>
            <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
              <AppNavigationLink to='/companies/transfer'>
                <img
                  src={SiteIcon}
                  alt='Systems'
                />
                <span>{t('TransferSite')}</span>
              </AppNavigationLink>
            </AppPermissionComponentGuard>
            <AppPermissionComponentGuard requiredRoles={[UserRole.RemoteSupportAccessP10]}>
              {featureFlags.isSupportServerAccessEnabled && (
                <AppNavigationLink to='/server-access'>
                  <img
                    src={SupportServerAccessIcon}
                    alt='Systems'
                  />
                  <span>{t('SupportServerAccess')}</span>
                </AppNavigationLink>
              )}
              {featureFlags.isDevModeEnabled && (
                <AppNavigationLink to='/dev-mode-server-access'>
                  <img
                    src={SupportServerAccessIcon}
                    alt='Systems'
                  />
                  <span>{t('DevModeSupportServerAccess')}</span>
                </AppNavigationLink>
              )}
            </AppPermissionComponentGuard>
            <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
              <AppNavigationLink to='/invoicing'>
                <img
                  className='app-h-15'
                  src={InvoiceIcon}
                  alt='Systems'
                />
                <span>{t('Invoicing')}</span>
              </AppNavigationLink>
            </AppPermissionComponentGuard>
            <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
              <AppNavigationLink to='/maintenance-emails'>
                <img
                  src={EmailIcon}
                  alt='Systems'
                />
                <span>{t('MaintenanceEmails')}</span>
              </AppNavigationLink>
            </AppPermissionComponentGuard>
          </div>
          <div
            role='button'
            tabIndex={0}
            className='app-mp-bold-16 app-px-30 app-py-12 app-navigation-link app-navigation-logout'
            onClick={msal.logout.bind(msal)}
          >
            <img
              src={LogoutIcon}
              alt='Logout'
            />
            <span>{t('Logout')}</span>
          </div>
        </div>
        <div className='app-portal'>
          {children}
        </div>
      </div>
    </>
  );
}
