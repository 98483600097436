import { EntryRegion, EntryRegionType, Urls } from '../backend/urls';
import { useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';
import { getUrlWithQueryParams, httpGetJson } from '../backend/http/http';
import { useContext } from 'react';
import { EntryAnalyticsContext } from '../../routes/home/entryAnalytics/EntryAnalyticsContext';

export enum ServiceRegion {
  All = 0,
  UnitedKingdom = 1,
  Benelux,
  Ireland,
  UnitedStates,
  Netherlands,
  Belgium,
  Luxembourg,
  Germany,
  France,
  SouthAfrica,
  Canada,
  Sweden,
  Norway,
  Denmark,
}

export interface IAdminPortalAnalytics {
  companiesCount: number;
  sitesCount: number;
  mobileUsersCount: number;
  paymentAdminsCount: number;
  siteAdminsCount: number;
  systemAdminsCount: number;
  panelsCount: number;
  monitorsCount: number;
  callCount: number;
  callAnsweredCount: number;
}

export interface ICallNotificationTimings {
  lowestCallNotificationMilliSeconds: number;
  highestCallNotificationMilliSeconds: number;
  averageNotificationMilliSeconds: number;
}

function getPrevMonthDate(dateObj: Date) {
	const tempDateObj = new Date(dateObj);

	if(tempDateObj.getMonth) {
		tempDateObj.setMonth(tempDateObj.getMonth() - 1);
	} else {
		tempDateObj.setFullYear(tempDateObj.getFullYear() - 1);
		tempDateObj.setMonth(12);
	}

	return tempDateObj
};

export function useTotalCount(options?:{prevMonth?: boolean}): IAdminPortalAnalytics & ICallNotificationTimings | null {
  const { entryRegion: region, company, site } = useContext(EntryAnalyticsContext);
  const companyId = company?.id;
  const siteId = site?.id;
  const date = new Date();

  const adminPortalAnalyticsUrl = getUrlWithQueryParams(Urls.TotalCount(region), {
    companyId,
    siteId,
    year: date.getFullYear(),
    month: options?.prevMonth 
      ? getPrevMonthDate(date).getMonth() + 1
      : date.getMonth() + 1,
  });

  const { data: adminPortalAnalytics } = useSWRAndHandleErrors<IAdminPortalAnalytics>(adminPortalAnalyticsUrl, httpGetJson);
  const callNotificationTimingsUrl = getUrlWithQueryParams(Urls.CallNotificationTimings(), { 
    companyId,
    siteId,
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });

  const { data: callNotificationTimings } = useSWRAndHandleErrors<ICallNotificationTimings>(callNotificationTimingsUrl, httpGetJson);

  return !adminPortalAnalytics || !callNotificationTimings ? null : {
    ...adminPortalAnalytics,
    ...callNotificationTimings,
  };
}

function getGrowth(originalCount: number, countChangedBy: number) {
  if (originalCount === 0) {
    return countChangedBy > 0 ? 1 : 0;
  }
  return countChangedBy / originalCount;
}

export function useGrowthPercents(): IAdminPortalAnalytics | null {
  const { entryRegion } = useContext(EntryAnalyticsContext);
  const totalCount = useTotalCount();
  const { data: countChangedByInLastMonth } = useSWRAndHandleErrors<IAdminPortalAnalytics>(
    Urls.ChangesForLastMonth(entryRegion),
    httpGetJson,
  );
  if (!countChangedByInLastMonth || !totalCount) {
    return null;
  } else {
    return Object.entries(countChangedByInLastMonth).reduce((acc, [key, value]) => {
      const castedKey = key as keyof IAdminPortalAnalytics;
      const castedValue = value as number;
      const castedAcc = acc as IAdminPortalAnalytics;
      return {
        ...castedAcc,
        [castedKey]: getGrowth(totalCount[castedKey], castedValue),
      };
    }, {}) as IAdminPortalAnalytics;
  }
}
