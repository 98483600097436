import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICompany } from '../../../shared/appBackend/useCompanies';
import { DeviceTypeIds, IDevice, isMonitor, rebootPanel, useDevices } from '../../../shared/appBackend/useDevices';
import { useLazyLoad } from '../../../shared/appBackend/useLazyLoad';
import { ISite } from '../../../shared/appBackend/useSites';
import AppSelect from '../../../shared/appUIFramework/components/AppSelect';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import { Urls } from '../../../shared/backend/urls';
import { ReactComponent as GreenTick } from '../../../assets/green tick.svg';
import { ReactComponent as WhiteTick } from '../../../assets/white tick.svg';
import { ReactComponent as RedCross } from '../../../assets/white tick.svg';
import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';
import { useAppTableScrollStyles } from '../../../styles';
import HomeShell from '../components/HomeShell';
import './Diagnostics.scss';
import { wsGet, wsSubscribe } from '../../../shared/backend/websockets/websockets';
import { httpGetString, httpPost } from '../../../shared/backend/http/http';
import AppCompaniesSelect from '../../../shared/appUIFramework/components/AppCompaniesSelect';
import { closePopup, showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import RebootConfirmationPopup from './RebootConfirmationPopup';
import AppOperationProcessingPopup from '../../supportServerAccess/AppOperationProcessingPopup';
import RebootSuccessPopup from './RebootSuccessPopup';
import AppRequestFailedPopup from '../../../shared/appUIFramework/components/AppRequestFailedPopup';

function getDeviceTypeTranslation(deviceTypeId: DeviceTypeIds) {
  return {
    [DeviceTypeIds.Unknown]: 'Unknown',
    [DeviceTypeIds.Mark1Panel]: 'MK1 Panel',
    [DeviceTypeIds.Mark1Monitor]: 'MK1 Monitor',
    [DeviceTypeIds.Mark2Panel]: 'MK2 Panel',
    [DeviceTypeIds.TouchPanel]: 'Touch Panel',
    [DeviceTypeIds.PremiumMonitor]: 'Premium Monitor',
    [DeviceTypeIds.Mark2Monitor]: 'MK2 Monitor',
    [DeviceTypeIds.AudioOnly]: 'Audio Only',
    [DeviceTypeIds.Mobile]: 'Mobile',
    [DeviceTypeIds.SIP]: 'SIP',
  }[deviceTypeId];
}

enum CallMessageType {
  Other,
  Idle,
  Calling,
  TokenRefresh,
  Notify,
  CallInProgress,
  CallEnded,
  StoppedMonitoring,
  FirmwareCheck = -100, // shown on UI as part of call messages, but that is not true, use -100 to prevent intersecion wth real API enum
}

enum CallEventType {
  Request,
  Response,
  Status,
}

interface ICallEventUIDtoFields {
  CallMessageType: CallMessageType;
  WasSuccessful: boolean;
}

interface ICallEvent extends ICallEventUIDtoFields {
  SiteId: string;
  EventType: CallEventType;
  PanelSerial: string;
  SessionId: string;
}

enum CallEventUIStateStatus {
  NotProcessed,
  Success,
  Error,
}

interface ICallEventUIState {
  status: CallEventUIStateStatus;
  CallMessageType: CallMessageType;
}

interface IFirmwareVersionPubSubResponse {
  panelSerial: string;
  siteId: string;
  currentFirmwareVersion: string;
  latestFirmwareVersion: string;
  result: string;
  code: number;
}

type CallTrackerEventsState = Record<CallMessageType, ICallEventUIState>;

function getInitialCallTrackerEventsState(): CallTrackerEventsState {
  return {
    [CallMessageType.FirmwareCheck]: {
      status: CallEventUIStateStatus.NotProcessed,
      CallMessageType: CallMessageType.FirmwareCheck,
    },
    [CallMessageType.Other]: { status: CallEventUIStateStatus.NotProcessed, CallMessageType: CallMessageType.Other },
    [CallMessageType.Calling]: {
      status: CallEventUIStateStatus.NotProcessed,
      CallMessageType: CallMessageType.Calling,
    },
    [CallMessageType.TokenRefresh]: {
      status: CallEventUIStateStatus.NotProcessed,
      CallMessageType: CallMessageType.TokenRefresh,
    },
    [CallMessageType.Notify]: { status: CallEventUIStateStatus.NotProcessed, CallMessageType: CallMessageType.Notify },
    [CallMessageType.CallInProgress]: {
      status: CallEventUIStateStatus.NotProcessed,
      CallMessageType: CallMessageType.CallInProgress,
    },
    [CallMessageType.CallEnded]: {
      status: CallEventUIStateStatus.NotProcessed,
      CallMessageType: CallMessageType.CallEnded,
    },
    [CallMessageType.StoppedMonitoring]: {
      status: CallEventUIStateStatus.NotProcessed,
      CallMessageType: CallMessageType.StoppedMonitoring,
    },
    [CallMessageType.Idle]: { status: CallEventUIStateStatus.NotProcessed, CallMessageType: CallMessageType.Idle },
  };
}

const callTrackerEventsTranslationKeys: Record<CallMessageType, string> = {
  [CallMessageType.FirmwareCheck]: 'FirmwareCheck',
  [CallMessageType.Other]: 'Other',
  [CallMessageType.Calling]: 'Calling',
  [CallMessageType.TokenRefresh]: 'TokenRefresh',
  [CallMessageType.Notify]: 'Notify',
  [CallMessageType.CallInProgress]: 'CallInProgress',
  [CallMessageType.CallEnded]: 'CallEnded',
  [CallMessageType.StoppedMonitoring]: 'StoppedMonitoring',
  [CallMessageType.Idle]: 'Idle',
};

const EnsurePanelHasInternetConnectionLinkId1 = '6856';
const EnsurePanelHasIpv4EnabledLinkId1 = '6856';
const EnsurePanelHasInternetConnectionLinkId2 = '6856';
const EnsurePanelHasIpv4EnabledLinkId2 = '6856';
const RebindToThePanelLinkId = '6857';
const EnsureCorrectPortsAreOpenedLinkId = '6858';
const CollectLogFilesAndForwardToDevOpsLinkId1 = '6859';

const renderLink = (linkId: string) => (
  <a className='app-help-link' href={`www.paxton.info/${linkId}`}>
    www.paxton.info/{linkId}
  </a>
);

const callTrackerEventsTroubleshootTranslationKeys: Record<CallMessageType, Array<[string, ReactNode | undefined]>> = {
  [CallMessageType.FirmwareCheck]: [
    [
      'UpdatingEntryFirmware',
      renderLink(RebindToThePanelLinkId),
    ],
  ],
  [CallMessageType.Calling]: [
    [
      'EnsurePanelHasInternetConnection',
      renderLink(EnsurePanelHasInternetConnectionLinkId1),
    ],
    [
      'EnsurePanelHasIpv4Enabled',
      renderLink(EnsurePanelHasIpv4EnabledLinkId1),
    ],
  ],
  [CallMessageType.TokenRefresh]: [
    [
      'EnsurePanelHasInternetConnection',
      renderLink(EnsurePanelHasInternetConnectionLinkId2),
    ],
    [
      'EnsurePanelHasIpv4Enabled',
      renderLink(EnsurePanelHasIpv4EnabledLinkId2),
    ],
    [
      'RebindToThePanel',
      renderLink(RebindToThePanelLinkId),
    ],
    ['AlertTheDevopsTeam', undefined],
  ],
  [CallMessageType.Notify]: [],
  [CallMessageType.CallInProgress]: [
    [
      'EnsureTheCorrectPortsAreOpened',
      renderLink(EnsureCorrectPortsAreOpenedLinkId),
    ],
  ],
  [CallMessageType.CallEnded]: [
    [
      'CollectLogFilesAndForwardToDevOps',
      renderLink(CollectLogFilesAndForwardToDevOpsLinkId1),
    ],
  ],
  [CallMessageType.StoppedMonitoring]: [], // not rendered on UI
  [CallMessageType.Idle]: [
    [
      'CollectLogFilesAndForwardToDevOps',
      renderLink(CollectLogFilesAndForwardToDevOpsLinkId1),
    ],
  ], // not rendered on UI
  [CallMessageType.Other]: [], // not rendered on UI
};

function CallTrackerEvent(
  { onClick, event, disabled }: {
    onClick: (event: ICallEventUIState) => void;
    event: ICallEventUIState;
    disabled: boolean;
  },
) {
  const { t } = useTranslation();
  const eventTranslationKey = callTrackerEventsTranslationKeys[event.CallMessageType];
  const eventTranslation = t(eventTranslationKey);
  const statusClass = {
    [CallEventUIStateStatus.NotProcessed]: 'app-diagnostics-call-tracker-event-not-processed',
    [CallEventUIStateStatus.Success]: 'app-diagnostics-call-tracker-event-success',
    [CallEventUIStateStatus.Error]: 'app-diagnostics-call-tracker-event-error',
  }[event.status];
  return disabled
    ? <div className='app-diagnostics-call-tracker-event app-diagnostics-call-tracker-event-disabled' />
    : (
      <div
        onClick={() => onClick(event)}
        className={`app-diagnostics-call-tracker-event app-text-align-center ${statusClass}`}
      >
        {eventTranslation}
        {event.status === CallEventUIStateStatus.Error && ` ${t('Failed')}`}
      </div>
    );
}

async function subscribeToEvents(
  siteId: string,
  panelSerial: string,
  onNewEvent: (event: ICallEventUIDtoFields) => void,
) {
  let subscribed = true;

  const doFirmwareCheck = async () => {
    const firmwareVersion = await wsGet<IFirmwareVersionPubSubResponse>(
      Urls.HardwareFirmware(siteId, panelSerial),
      Urls.HardwareFirmwareNegotiate(siteId),
    );

    if (
      firmwareVersion.code !== 200
      || firmwareVersion.currentFirmwareVersion !== firmwareVersion.latestFirmwareVersion
    ) {
      if (subscribed) {
        onNewEvent({
          CallMessageType: CallMessageType.FirmwareCheck,
          WasSuccessful: false,
        });
      }

      return () => { };
    }

    if (subscribed) {
      onNewEvent({
        CallMessageType: CallMessageType.FirmwareCheck,
        WasSuccessful: true,
      });
    }
  };

  const unsub = await wsSubscribe<ICallEvent>(onNewEvent, async () => {
    const res = await httpGetString(Urls.CallTrackerEventsNegotiate(siteId));
    return res.replaceAll('"', '');
  });

  // after websocket opened
  // need to wait for a second, otherwise few first events after call to start may be missed
  await new Promise(resolve => setTimeout(resolve, 2000));
  // don't need to await comletion, since firmware can be checked in parallel
  doFirmwareCheck();
  await httpPost(Urls.CallTrackerEventsStartListen(siteId, panelSerial, false), {});

  return () => {
    subscribed = false;
    unsub();
  };
}

export default function Diagnostics() {
  // dependencies
  const { t } = useTranslation();

  // state
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

  const [sitesSearchString, setSitesSearchString] = useState('');
  const sitesUrl = selectedCompany ? Urls.Sites(selectedCompany.id) : undefined;
  const {
    items: sites,
    loadMore: loadMoreSites,
    lastPageLoaded: lastPageOfSitesLoaded,
    loading: isSitesLoading,
  } = useLazyLoad<ISite>(sitesUrl, {
    search: sitesSearchString,
  }, {
    initialPage: 0,
  });
  const [selectedSite, setSelectedSite] = useState<ISite | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState<number | null>(null);
  const setDeviceState = (device: IDevice | null, deviceIndex: number | null) => {
    setSelectedDevice(device);
    setSelectedDeviceIndex(deviceIndex);
  };
  const { devices, loading: isDevicesLoading, isOffline } = useDevices(selectedSite);
  const [callTrackerEvents, setCallTrackerEvents] = useState<CallTrackerEventsState>(
    getInitialCallTrackerEventsState(),
  );
  const [callTrackerConnected, setCallTrackerConnected] = useState(false);
  const callTrackerDisabled = selectedDevice == null;
  const [activeEvent, setActiveEvent] = useState<ICallEventUIState | null>(null);
  const activeEventTroubleShoouting = activeEvent
    ? callTrackerEventsTroubleshootTranslationKeys[activeEvent.CallMessageType]
    : [];

  const selectCompany = (company?: ICompany | null) => {
    disconnectDevice();
    setDeviceState(null, null);
    setSelectedSite(null);
    setSelectedCompany(company || null);
  };

  const selectSite = (siteName: string) => {
    disconnectDevice();
    setDeviceState(null, null);
    const site = sites.find(s => s.siteName === siteName);
    setSelectedSite(site || null);
  };

  const selectDevice = (deviceIndex: number) => {
    disconnectDevice();
    const device = devices?.[deviceIndex];
    setDeviceState(device || null, device ? deviceIndex : null);
  };

  const connectDevice = () => {
    setCallTrackerConnected(true);
    setCallTrackerEvents(getInitialCallTrackerEventsState());
    setActiveEvent(null);
  };

  const disconnectDevice = () => {
    setCallTrackerConnected(false);
    setCallTrackerEvents(getInitialCallTrackerEventsState());
    setActiveEvent(null);
  };

  const resetTrace = () => {
    setCallTrackerConnected(false);
    setCallTrackerEvents(getInitialCallTrackerEventsState());
    setActiveEvent(null);
    setTimeout(() => {
      setCallTrackerConnected(true);
    }, 10); // delay to trigger useEffect of the callTrackerConnected
  };

  const rebootDevice = async () => {
    if (!selectedDevice) {
      return;
    }

    if (!selectedSite) {
      return;
    }

    const engineerCode = await showPopup(<RebootConfirmationPopup />);
    if (!engineerCode) {
      return;
    }

    showPopup(<AppOperationProcessingPopup titleKey='RebootPanel' bodyKey='SendingRebootMessage' useSimpleBodyStyle />);
    try {
      await rebootPanel(selectedDevice.serialNumber, selectedSite.id, engineerCode);
      setSelectedDevice(null);
      resetTrace();
      closePopup();
      await showPopup(<RebootSuccessPopup />);
    } catch (error) {
      closePopup();
      await showPopup(<AppRequestFailedPopup titleKey='RebootPanel' />);
    }
  };

  // effects
  // when callTracker connected/disconnected subscribe/unsubscribe to the events
  useEffect(() => {
    let unsubscribe: (() => void) | undefined = undefined;

    const isConnected = callTrackerConnected && selectedSite && selectedDevice;
    if (isConnected) {
      subscribeToEvents(selectedSite.id, selectedDevice.serialNumber, event => {
        setCallTrackerEvents(prevState => {
          const newEvent = {
            status: event.WasSuccessful ? CallEventUIStateStatus.Success : CallEventUIStateStatus.Error,
            CallMessageType: event.CallMessageType,
          };

          setActiveEvent(newEvent);

          // on call ended mark all not processed events as failed
          if (newEvent.CallMessageType === CallMessageType.CallEnded) {
            Object.entries(prevState)
              .forEach(([key, value]) => {
                if (value.status === CallEventUIStateStatus.NotProcessed) {
                  const castedKey = key as unknown as CallMessageType;
                  prevState[castedKey] = {
                    ...prevState[castedKey],
                    status: CallEventUIStateStatus.Error,
                  };
                }
              });
          }

          return ({
            ...prevState,
            [event.CallMessageType]: newEvent,
          });
        });
      })
        .then(async unsub => {
          unsubscribe = unsub;
        })
        .catch(() => { // if error failed in the subscription, also it may happen if panel in rebooting or offline state
          showPopup(<AppRequestFailedPopup />);
        });
    }

    const twoMinsInMs = 1000 * 60 * 2;
    const timeoutId = setTimeout(() => {
      disconnectDevice();
    }, twoMinsInMs);

    return (() => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (unsubscribe) {
        unsubscribe();
        unsubscribe = () => { };
      }
    });
  }, [callTrackerConnected, selectedSite, selectedDevice]);

  // refs
  const tableContentRef = useRef<HTMLDivElement | null>(null);
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  useAppTableScrollStyles({ tableContentRef, tableHeaderRef, isTableVisible: devices != null });
  const isHybrid = devices?.some(p => p.typeId === DeviceTypeIds.Mark1Panel);

  return (
    <HomeShell activeTab={'diagnostics'}>
      <div className='app-d-flex app-gap-50'>
        <div className='app-w-40pcnt app-d-flex app-gap-30 app-flex-column'>
          <div className='app-form-control'>
            <div className='app-form-control-label'>
              {t('Company')}
            </div>
            <AppCompaniesSelect
              company={selectedCompany}
              setCompany={selectCompany}
            />
          </div>
          <div className='app-form-control' aria-disabled={!selectedCompany}>
            <div className='app-form-control-label'>
              {t('Site')}
            </div>
            <AppSelect
              disabled={!selectedCompany}
              className='app-form-input'
              outlineSearch
              outlineSearchPlaceholder={t('SearchSites')}
              onOutlineSearchChange={e => {
                setSitesSearchString(e.target.value);
              }}
              onClosed={() => {
                setSitesSearchString('');
              }}
              onOptionsScrolledToBottom={loadMoreSites}
              areOptionsLoading={isSitesLoading}
              lastPageLoaded={lastPageOfSitesLoaded}
              showNoOptions
              incomingValue={selectedSite?.siteName || ''}
              options={sites.map(p => p.siteName)}
              placeholder={t('SelectSite')}
              onOptionSelected={selectSite}
            />
          </div>
          {isHybrid && (
            <div className='app-d-flex app-align-items-center app-pl-20 app-gap-15 app-font-20'>
              <div>
                <WarningIcon />
              </div>
              <div>
                <span className='app-weight-600'>{t('HybridSite')}</span>
                <span>:&nbsp;</span>
                <span>{t('Mk1PanelsWillNotBeAbleToMakeCalls')}</span>
              </div>
            </div>
          )}
          <div className='app-table app-table-diagnostics-sizes'>
            <div className='app-table-header-row' ref={tableHeaderRef}>
              <div>{t('Device')}</div>
              <div>{t('Type')}</div>
              <div>{t('SerialNumber')}</div>
              <div>{t('Status')}</div>
            </div>
            <div className='app-table-content' ref={tableContentRef}>
              <AppShowLoading showLoading={isDevicesLoading}>
                {isOffline
                  && (
                    <div className='app-flex-vertical-scrollable app-align-items-center app-bold-26 app-justify-content-center app-d-flex'>
                      <span>{t('DeviceIsOffline')}</span>
                    </div>
                  )}
                {devices && devices.length > 0 && devices.map((p, index) => (
                  <div
                    key={index}
                    onClick={() => !isMonitor(p.typeId) && selectDevice(index)}
                    aria-disabled={isMonitor(p.typeId)}
                    tabIndex={0}
                    role='button'
                    className={`app-table-content-row ${selectedDeviceIndex === index ? 'app-table-content-row-active' : ''
                      }`}
                  >
                    <div>{p.name}</div>
                    <div>{getDeviceTypeTranslation(p.typeId)}</div>
                    <div>{p.serialNumber}</div>
                    <div className='app-d-flex app-align-items-center app-gap-10'>
                      <span>{p.status}</span>
                      {p.typeId !== DeviceTypeIds.Mark1Panel && (
                        <>
                          {p.status === 'Online' && selectedDeviceIndex !== index && <GreenTick />}
                          {p.status === 'Online' && selectedDeviceIndex === index && <WhiteTick />}
                          {p.status === 'Offline' && <RedCross />}
                        </>
                      )}
                      {p.typeId === DeviceTypeIds.Mark1Panel && (
                        <>
                          <WarningIcon />
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </AppShowLoading>
            </div>
          </div>
        </div>
        <div className='app-w-60pcnt app-d-flex app-gap-30 app-flex-column'>
          <div className='app-font-20 app-weight-600'>{t('EntryCallTracker')}</div>
          <div className='app-diagnostics-call-tracker-card'>
            <div className='app-d-flex app-justify-content-between'>
              {!callTrackerConnected && (
                <button
                  className='app-button app-primary-button'
                  disabled={callTrackerDisabled || selectedDevice?.status !== 'Online'}
                  onClick={connectDevice}
                >
                  {t('Connect')}
                </button>
              )}
              {callTrackerConnected && (
                <button className='app-button app-secondary-button' onClick={disconnectDevice}>
                  {t('Disonnect')}
                </button>
              )}
              <div className='app-text-align-center'>
                <button
                  className='app-button app-secondary-button'
                  disabled={!selectedDevice}
                  onClick={rebootDevice}
                >
                  {t('Reboot')}
                </button>
              </div>
              <div className='app-text-align-center'>
                <button
                  className='app-button app-secondary-button'
                  disabled={!callTrackerConnected}
                  onClick={resetTrace}
                >
                  {t('ResetTrace')}
                </button>
              </div>
            </div>
            <div className='app-diagnostics-call-tracker'>
              <div className='app-w-50pcnt'>
                <div className='app-text-align-center'>
                  <div className='app-d-flex app-gap-10 app-justify-content-center app-flex-column'>
                    <CallTrackerEvent
                      onClick={setActiveEvent}
                      disabled={!callTrackerConnected}
                      event={callTrackerEvents[CallMessageType.FirmwareCheck]}
                    />
                    <CallTrackerEvent
                      onClick={setActiveEvent}
                      disabled={!callTrackerConnected}
                      event={callTrackerEvents[CallMessageType.Calling]}
                    />
                    <CallTrackerEvent
                      onClick={setActiveEvent}
                      disabled={!callTrackerConnected}
                      event={callTrackerEvents[CallMessageType.TokenRefresh]}
                    />
                    <CallTrackerEvent
                      onClick={setActiveEvent}
                      disabled={!callTrackerConnected}
                      event={callTrackerEvents[CallMessageType.CallInProgress]}
                    />
                    <CallTrackerEvent
                      onClick={setActiveEvent}
                      disabled={!callTrackerConnected}
                      event={callTrackerEvents[CallMessageType.CallEnded]}
                    />
                  </div>
                </div>
              </div>
              <div className='app-w-50pcnt'>
                <div className='app-font-20 app-weight-600'>{t('Troubleshooting')}</div>
                <div className='app-diagnostics-call-tracker-troubleshooting'>
                  {
                    <div className='app-d-flex app-flex-column app-gap-20'>
                      {selectedDevice && selectedDevice.typeId === DeviceTypeIds.Mark1Panel && (
                          <div
                            className='app-d-flex app-align-items-center app-gap-10 app-diagnostics-call-tracker-troubleshooting-item'
                          >
                            <div>
                              <WarningIcon />
                            </div>
                            <div>
                              <span>{t('PaxtonEntryAppIsNotSupportOnMk1')}</span>
                            </div>
                          </div>
                      )}
                      {activeEventTroubleShoouting.length > 0
                        && activeEventTroubleShoouting.map((p, index) => (
                          <div
                            key={index}
                            className='app-d-flex app-align-items-center app-gap-10 app-diagnostics-call-tracker-troubleshooting-item'
                          >
                            <div>
                              <GreenTick />
                            </div>
                            <div>
                              <span>{t(p[0])}</span>
                              {p[1] && (
                                <>
                                  <br />
                                  <span>{p[1]}</span>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HomeShell>
  );
}
