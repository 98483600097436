import { useTranslation } from 'react-i18next';
import { useFormatNumber } from '../../../../shared/formatters/formatNumber';
import { ReactComponent as TimingsPhoneIcon } from '../../../../assets/timings_phone.svg';
import { ReactComponent as FastestCallIcon } from '../../../../assets/Fastest call notification icon.svg';
import { ReactComponent as LongestCallIcon } from '../../../../assets/Longest Call notification icon.svg';
import { ReactNode } from 'react';
import { useTotalCount } from '../../../../shared/appBackend/useMetrics';
import AppShowLoading from '../../../../shared/appUIFramework/components/AppShowLoading';

function CallTimingsElement({ icon, title, count: countMs }: { icon: ReactNode, title: string, count?: number }) {
  const formatNumber = useFormatNumber();
  const { t } = useTranslation();
  return (
    <div className='app-d-flex app-gap-20 app-align-items-center'>
      <div>
        {icon}
      </div>
      <div className='app-font-12 app-weight-600 app-uppercase app-w-130 app-text-wrap'>
        {title}
      </div>
      <div className='app-font-26 app-color-primary-green app-weight-600'>
        <AppShowLoading inline showLoading={countMs == null}>
          <span>{countMs && formatNumber(countMs / 1000, 1)}</span>
          &nbsp;
          <span className='app-uppercase'>{t('Seconds')}</span>
        </AppShowLoading>
      </div>
    </div>
  );
}

export default function CallTimingsWidget({ width50 }: { width50?: boolean }) {
  const { t } = useTranslation();
  const totalCount = useTotalCount();

  return (
    <div className={`app-home-widget app-home-widget-call-rate ${width50 ? 'app-w-50pcnt' : ''}`}>
      <div className='app-home-widget-title app-mb-46'>
        <span className='app-home-widget-title-text'>{t('CallTimings')}</span>
      </div>
      <div className='app-d-flex app-gap-70 app-flex-column app-align-items-center'>
        <CallTimingsElement
          icon={<FastestCallIcon />}
          title={t('FastestCallNotificationTime')}
          count={totalCount?.lowestCallNotificationMilliSeconds}
        />
        <CallTimingsElement
          icon={<LongestCallIcon />}
          title={t('LongestCallNotificationTime')}
          count={totalCount?.highestCallNotificationMilliSeconds}
        />
        <CallTimingsElement
          icon={<TimingsPhoneIcon />}
          title={t('AverageCallNotificationTime')}
          count={totalCount?.averageNotificationMilliSeconds}
        />
      </div>
    </div>
  );
}
