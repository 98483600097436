import { createContext } from 'react';
import { EntryRegionType } from '../../../shared/backend/urls';
import { ICompany } from '../../../shared/appBackend/useCompanies';
import { ISite } from '../../../shared/appBackend/useSites';

interface IEntryAnalyticsContext {
  entryRegion: EntryRegionType;
  company: ICompany | null;
  site: ISite | null;
}

export const EntryAnalyticsContext = createContext<IEntryAnalyticsContext>({
  entryRegion: null,
  company: null,
  site: null,
});
